import {iuvpaaSupportLevel, webauthnSupportLevel} from '../webauthn-support-level'
import {get, parseRequestOptionsFromJSON} from '@github/webauthn-json/browser-ponyfill'
import type {CredentialRequestOptionsJSON} from '@github/webauthn-json/browser-ponyfill'
import {requestSubmit, changeValue} from '../form'
// eslint-disable-next-line no-restricted-imports
import {observe} from 'selector-observer'

interface WebauthnWindow extends Global {
  PublicKeyCredential?: {
    isConditionalMediationAvailable?: () => Promise<boolean>
  }
}

async function supportConditionalMediation(): Promise<void> {
  const conditionalForm = document.querySelector<HTMLFormElement>('.js-conditional-webauthn-placeholder')!
  const isAvailable = await (globalThis as WebauthnWindow).PublicKeyCredential?.isConditionalMediationAvailable?.()

  if (conditionalForm && isAvailable) {
    document.querySelector('#login_field')?.setAttribute('autocomplete', 'username webauthn')
    const signRequest = conditionalForm.getAttribute('data-webauthn-sign-request')

    if (!signRequest) {
      return
    }
    const signRequestJSON: CredentialRequestOptionsJSON = JSON.parse(signRequest)
    const signResponse = await get(parseRequestOptionsFromJSON(signRequestJSON))
    const responseField = conditionalForm.querySelector<HTMLFormElement>('.js-webauthn-response')!
    responseField.value = JSON.stringify(signResponse)
    requestSubmit(conditionalForm)
  }
}

// Record the browser's webauthn support level in the GitHub login form.
// Ask the device to sign a request when the user taps its button.
observe('.js-webauthn-support', {
  constructor: HTMLInputElement,
  add(el) {
    changeValue(el, webauthnSupportLevel())
  }
})

observe('.js-webauthn-iuvpaa-support', {
  constructor: HTMLInputElement,
  async add(el) {
    changeValue(el, await iuvpaaSupportLevel())
  }
})

observe('.js-conditional-webauthn-placeholder', function () {
  supportConditionalMediation()
})
